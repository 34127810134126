function PillSwitcherModal(props) {
  function returnPill(value) {
    switch (value) {
      case ("1", 1):
        return <span class="badge badge-success">Sent</span>;
        break;
      default:
        return <span class="badge badge-secondary">Scheduled</span>;
    }
  }

  return <div>{returnPill(props?.data?.current_value)}</div>;
}

export default PillSwitcherModal;
