import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";
import DateTimePickerField from "../Components/Tables/CustomComponents/DateTimePickerField";
import TimestampPreview from "../Components/Tables/CustomComponents/TimestampPreview";
import PillSwitcher from "../Components/Tables/CustomComponents/PillSwitcher";
import PillSwitcherModal from "../Components/Tables/CustomComponents/PillSwitcherModal";

const components = {
  DayDropDown: DayDropDown,
  TimestampPreview: TimestampPreview,
  DateTimePickerField: DateTimePickerField,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
  PillSwitcher: PillSwitcher,
  PillSwitcherModal: PillSwitcherModal,
};

function NotificationSchedule() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Title",
      value: "title",
      editable: true,
      place_holder: "Shake today to see what you have won!",
      modal_component: "Text",
    },
    {
      title: "Notification",
      value: "notification",
      editable: true,
      place_holder: "Shake today to see what you have won!",
      modal_component: "Text",
    },
    {
      title: "Scheduled Time",
      value: "date_time",
      editable: true,
      modal_component: "DateTimePickerField",
      custom_component: "TimestampPreview",
    },
    {
      title: "Created At",
      value: "created_at",
      editable: false,
      place_holder: "",
      modal_component: "Text",
    },
    {
      title: "Status",
      value: "status",
      editable: false,
      modal_component: "PillSwitcherModal",
      custom_component: "PillSwitcher",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Title",
        value: "title",
        editable: true,
        place_holder: "Have you Shaken Today 👀",
        modal_component: "Text",
      },
      {
        title: "Notification",
        value: "notification",
        editable: true,
        place_holder: "Shake today to see what you have won!",
        modal_component: "Text",
      },
      {
        title: "Scheduled Time",
        value: "date_time",
        editable: true,
        place_holder: "",
        modal_component: "DateTimePickerField",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Notifications"}
            buttonText={"New Notification"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deletenotification.php"}
            getEP={"/notifications.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addnotification.php"}
          deleteEP={"/deletenotification.php"}
          editEP={"/updatenotification.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default NotificationSchedule;
